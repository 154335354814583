// eslint-disable-next-line no-unused-vars
/* eslint-disable no-unused-vars */

import { useNavigate, useLocation, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  BankOutlined,
  // TransactionOutlined,
  // MoneyCollectOutlined,
  // MonitorOutlined,
  // PieChartOutlined,
} from "@ant-design/icons";
import Header from "./header";
import DashboardPage from "../pages/dashboard";
import { RoleProtected } from "../routes/RoleProtected";
import { ROUTE_KEY } from "../common/const";
//transfer

const BillTransferPage = React.lazy(() => import("../pages/bill/transfer"));
const BillTransferMSBPage = React.lazy(() => import("../pages/bill/transfer/msb"));
// const BillTransferVCBPage = React.lazy(() => import("../pages/bill/transfer/vcb"));
const BillTransferVCBPage = React.lazy(() => import("../pages/bill/transfer/vcbNew"));
const BillTransferTECHPage = React.lazy(() => import("../pages/bill/transfer/tech"));
const BillTransferBIDVPage = React.lazy(() => import("../pages/bill/transfer/bidv"));
const BillTransferSacombankPage = React.lazy(() => import("../pages/bill/transfer/sacombank"));
const BillTransferVietinbankPage = React.lazy(() => import("../pages/bill/transfer/vietinbank"));
const BilltransferVPbankPage = React.lazy(() => import("../pages/bill/transfer/vpbank"));
const BillTransferMBbankPage = React.lazy(() => import("../pages/bill/transfer/mbbankv2"));
// const BillTransferMBbankPage = React.lazy(() => import("../pages/bill/transfer/mbbank"));
// const BillTransferMomoPage = React.lazy(() => import("../pages/bill/transfer/momo"));
const BillTransferMomoPagev2 = React.lazy(() => import("../pages/bill/transfer/momo/indexv2"));
const BillTransferAgribank = React.lazy(() => import("../pages/bill/transfer/agri"));
const BillTransferTpBank = React.lazy(() => import("../pages/bill/transfer/tpbank"));
const BillTransferACB = React.lazy(() => import("../pages/bill/transfer/acb"));
// const BillTransferOCB = React.lazy(() => import("../pages/bill/transfer/ocb"));
const BillTransferOCB = React.lazy(() => import("../pages/bill/transfer/ocbnew"));
const BillTransferDongA = React.lazy(() => import("../pages/bill/transfer/donga"));
const BillTransferPvcombank = React.lazy(() => import("../pages/bill/transfer/pvcombank"));
const BillTransferNamAbank = React.lazy(() => import("../pages/bill/transfer/nama"));
const BillTransferCakebank = React.lazy(() => import("../pages/bill/transfer/cake"));
const BillTransferSeabank = React.lazy(() => import("../pages/bill/transfer/seabank"));
const BillTransferLpbank = React.lazy(() => import("../pages/bill/transfer/lpbank"));
const BillTransferBvbank = React.lazy(() => import("../pages/bill/transfer/bvbank"));
const BillTransferInvoice = React.lazy(() => import("../pages/bill/transfer/invoice"));
const BillTransferViettelMoney = React.lazy(() => import("../pages/bill/transfer/viettelMoney"));
const BillTransferSHBBank = React.lazy(() => import("../pages/bill/transfer/shbbank"));
const BillTransferHDBank = React.lazy(() => import("../pages/bill/transfer/hdbank"));
//cons
//End transfer
//transaction

const BillTransactionPage = React.lazy(() => import("../pages/bill/transaction"));
// const BillTransactionVCBPage = React.lazy(() => import("../pages/bill/transaction/vcb"));
const BillTransactionVCBPage = React.lazy(() => import("../pages/bill/transaction/vietcombank2"));
const BillTransactionTCBPage = React.lazy(() => import("../pages/bill/transaction/techcombankv2"));
const BillTransactionAcbPage = React.lazy(() => import("../pages/bill/transaction/acbv2"));
const BillTransactionMBBPage = React.lazy(() => import("../pages/bill/transaction/mbbankv2"));
const BillTransactionTPBPage = React.lazy(() => import("../pages/bill/transaction/tpbank"));
const BillTransactionMomoPage = React.lazy(() => import("../pages/bill/transaction/momo"));
const BillTransacAgribank = React.lazy(() => import("../pages/bill/transaction/agribank"));
const BillTransacVietinbank = React.lazy(() => import("../pages/bill/transaction/vietinbank"));
const BillTransacBIDV = React.lazy(() => import("../pages/bill/transaction/bidv"));
const BillTransacVpBank = React.lazy(() => import("../pages/bill/transaction/vpbank"));
const BillTransacOCB = React.lazy(() => import("../pages/bill/transaction/ocb"));

//End transactiond
//balance

const BillBalancePage = React.lazy(() => import("../pages/bill/balance"));
const BillBalanceACBPage = React.lazy(() => import("../pages/bill/balance/acbV2"));
const BillBalanceBIDVPage = React.lazy(() => import("../pages/bill/balance/bidvV2"));
const BillBalanceAgribankPage = React.lazy(() => import("../pages/bill/balance/agribankv2"));
const BillBalanceVietComBankPage = React.lazy(() => import("../pages/bill/balance/vcbv2"));
const BillBalanceTpBank = React.lazy(() => import("../pages/bill/balance/tpbankv2"));
const BillBalanceVpBankPage = React.lazy(() => import("../pages/bill/balance/vpbankv2"));
const BillBalanceViettinPage = React.lazy(() => import("../pages/bill/balance/vietinbankv2"));
const BillBalanceMbbanhk = React.lazy(() => import("../pages/bill/balance/mbBankv2"));
const BillBalanceTechComBank = React.lazy(() => import("../pages/bill/balance/techcombank"));

//End balance
//smsBanking
const SmsBankingPage = React.lazy(() => import("../pages/bill/smsbanking"));
const SmsBankVietComBankPage = React.lazy(() => import("../pages/bill/smsbanking/vietcombank"));
const SmsBankDongABank = React.lazy(() => import("../pages/bill/smsbanking/dongabank"));
const SmsBankBidv = React.lazy(() => import("../pages/bill/smsbanking"));
const SmsBankAgribankBankPage = React.lazy(() => import("../pages/bill/smsbanking/agribank"));
const SmsBankVietinBankPage = React.lazy(() => import("../pages/bill/smsbanking/vietinbank"));
const SmsBankACB = React.lazy(() => import("../pages/bill/smsbanking/acb"));
const SmsBankTpbank = React.lazy(() => import("../pages/bill/smsbanking/tpbank"));
const SmsBankMBBankPage = React.lazy(() => import("../pages/bill/smsbanking/mbbank"));
const SmsBankOCBBankPage = React.lazy(() => import("../pages/bill/smsbanking/ocbbank"));

//end smsBanking
//history
const HistoryPageNew88 = React.lazy(() => import("../pages/history/new88v2"));
const HistoryPageQQ88 = React.lazy(() => import("../pages/history/qq88"));

const HistoryPageHi88 = React.lazy(() => import("../pages/history/hi88"));
const HistoryPageShBet = React.lazy(() => import("../pages/history/shbetv2"));
const HistoryPageJun88 = React.lazy(() => import("../pages/history/jun88/indexv2"));
const Bet789HistoryPage = React.lazy(() => import("../pages/history/789betv2"));
const WIN78HistoryPage = React.lazy(() => import("../pages/history/78win"));
const HistoryPageF168 = React.lazy(() => import("../pages/history/f168"));
const HistoryPageF8bet = React.lazy(() => import("../pages/history/f8betv2"));
const HistoryPageMb66 = React.lazy(() => import("../pages/history/mb66v2"));
const HistoryPageOk9 = React.lazy(() => import("../pages/history/ok9"));
//End history
//history beeting
const HistoryBettingPage = React.lazy(() => import("../pages/historyBetting/qq88"));
const HistoryBettingPageF168 = React.lazy(() => import("../pages/historyBetting/f168"));

//homeScreen
// const HomeScreenNew88 = React.lazy(() => import("../pages/homeScreen/new88"));
const HomeScreenNew88 = React.lazy(() => import("../pages/homeScreen/new88v2"));
const HomeScreenQq88 = React.lazy(() => import("../pages/homeScreen/qq88"));
const HomePage789Bet = React.lazy(() => import("../pages/homeScreen/789Bet"));
const HomePage78Win = React.lazy(() => import("../pages/homeScreen/78win"));
const HomePageJun88 = React.lazy(() => import("../pages/homeScreen/jun88v2"));
const HomeScreenHi88 = React.lazy(() => import("../pages/homeScreen/hi88v2"));

const HomeScreenF8bet = React.lazy(() => import("../pages/homeScreen/f8betv2"));
const HomeScreenF168 = React.lazy(() => import("../pages/homeScreen/F168"));
const HomeScreenMb66 = React.lazy(() => import("../pages/homeScreen/mb66"));
const HomeScreenShBet = React.lazy(() => import("../pages/homeScreen/shbetv2"));
const HomeScreenOk9 = React.lazy(() => import("../pages/homeScreen/okvip9"));

//End homeScreen
//gameScreen
// const GamePageNew88 = React.lazy(() => import("../pages/gameScreen/new88"));
const GamePageNew88 = React.lazy(() => import("../pages/gameScreen/new88v2"));
const GamePage789Bet = React.lazy(() => import("../pages/gameScreen/789Bet"));
const GamePage78Win = React.lazy(() => import("../pages/gameScreen/78win"));
const GamePageJun88 = React.lazy(() => import("../pages/gameScreen/jun88"));
const GamePageHi88 = React.lazy(() => import("../pages/gameScreen/hi88v2"));
const GamePageF8bet = React.lazy(() => import("../pages/gameScreen/f8betv2"));
const GamePageF168 = React.lazy(() => import("../pages/gameScreen/f168"));
const GamePageMb66 = React.lazy(() => import("../pages/gameScreen/mb66"));
const GamePageQQ88 = React.lazy(() => import("../pages/gameScreen/qq88"));
const GamePageOk9 = React.lazy(() => import("../pages/gameScreen/ok9"));
// const GamePageShbet = React.lazy(() => import("../pages/gameScreen/shbet"));
const GamePageShbet = React.lazy(() => import("../pages/gameScreen/shbet"));

//End gameScreen
// //widthDrawMoney
// const DrawMoneyPageNew88 = React.lazy(() => import("../pages/widthDrawMoney/new88"));
const DrawMoneyPageNew88 = React.lazy(() => import("../pages/widthDrawMoney/new88v2"));
const DrawMoneyPageHi88 = React.lazy(() => import("../pages/widthDrawMoney/hi88v2"));
const DrawMoneyPageShBet = React.lazy(() => import("../pages/widthDrawMoney/shbetv2"));
const DrawMoneyPageJun88 = React.lazy(() => import("../pages/widthDrawMoney/jun88"));
const WithdrawalRequest78win = React.lazy(() => import("../pages/widthDrawMoney/78win"));
const WithdrawalRequest789 = React.lazy(() => import("../pages/widthDrawMoney/789betv2"));
const WithdrawalRequestF8bet = React.lazy(() => import("../pages/widthDrawMoney/f8bet"));
const WithdrawalRequestmb66 = React.lazy(() => import("../pages/widthDrawMoney/mb66"));
const WithdrawalRequestok9 = React.lazy(() => import("../pages/widthDrawMoney/ok9"));
const WithdrawalRequestF168 = React.lazy(() => import("../pages/widthDrawMoney/F168"));
// //End widthDrawMoney
// widthdrawalHistory
const WidthdrawalHistoryJun88 = React.lazy(() => import("../pages/widthdrawalHistory/jun88"));
const WidthdrawalHistoryMb66 = React.lazy(() => import("../pages/widthdrawalHistory/mb66"));
//refuntHistory
const RefuntHistory = React.lazy(() => import("../pages/refundHistory/jun88"));
//noticewidth
const NoticeJun88 = React.lazy(() => import("../pages/noticeWidthdrawn/jun88"));
const NoticeMb66 = React.lazy(() => import("../pages/noticeWidthdrawn/mb66"));
//promotion
const PromotionHi88 = React.lazy(() => import("../pages/promotion/hi88"));
const PromotionQQ88 = React.lazy(() => import("../pages/promotion/qq88"));
//prize
const PrizeQQ88 = React.lazy(() => import("../pages/prize/qq88"));

//endproze

// level vip
const LevelVipNew88 = React.lazy(() => import("../pages/levelVip/new88v2"));
const LevelVip789BET = React.lazy(() => import("../pages/levelVip/789BET"));
const LevelVip78WIN = React.lazy(() => import("../pages/levelVip/78win"));
const LevelVipF168 = React.lazy(() => import("../pages/levelVip/f168"));
const LevelVipJun88 = React.lazy(() => import("../pages/levelVip/Jun88"));
const LevelVipHi88 = React.lazy(() => import("../pages/levelVip/hi88"));
const LevelVipMb66 = React.lazy(() => import("../pages/levelVip/mb66"));
// const LevelVipSHBET = React.lazy(() => import("../pages/levelVip/shbet"));
const LevelVipSHBET = React.lazy(() => import("../pages/levelVip/shbetv2"));

// End widthdrawalHistory
// Profile
const ProfilePage = React.lazy(() => import("../pages/auth/profile"));

// End Profile

// create invoice
const CreateInvoicePage = React.lazy(() => import("../pages/invoice"));
//client
const CreateInformation = React.lazy(() => import("../pages/webapp"));
const DownloadApp = React.lazy(() => import("../pages/dowloadApp"));
const CreateClient = React.lazy(() => import("../pages/webapp/beneficary"));
const FileApp = React.lazy(() => import("../pages/dowloadApp/fileApp"));
//uploadImage
const UploadImage = React.lazy(() => import("../pages/bill/uploadImage"));

const { Content, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const authProtectedRoutes = [
  {
    label: "Trang chủ",
    key: "dashboard",
    icon: <DashboardOutlined />,
    children: [
      {
        label: "Tổng Quan",
        key: "subdashboard",
        path: "/dashboard",
        component: <DashboardPage />,
        role: null,
      },
    ],
  },
  {
    label: "Tạo Bill", //Parant Menu
    key: "bill",
    icon: <BankOutlined />,
    children: [
      {
        label: "Bill chuyển khoản",
        key: "billTransfer",
        path: "/billTransfer",
        component: <BillTransferPage />,
      },
      {
        label: "Bill biến động",
        key: "billTransaction",
        path: "/billTransaction",
        component: <BillTransactionPage />,
      },
      {
        label: "Bill số dư",
        key: "billBalance",
        path: "/billBalance",
        component: <BillBalancePage />,
      },
      {
        label: "SMS Banking",
        key: "smsbanking",
        path: "/smsbanking",
        component: <SmsBankingPage />,
      },
      {
        label: "Upload hình nền",
        key: "upload",
        path: "/upload-wallpaper",
        component: <UploadImage />,
      },
    ],
  },
  {
    label: "Yêu Cầu Rút", //Parant Menu
    key: "withDrawMoney",
    children: [
      {
        label: "Jun88",
        key: "jun88",
        path: "/withDrawMoney/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <DrawMoneyPageJun88 />,
      },
      {
        label: "NEW88",
        key: "new88",
        path: "/withDrawMoney/New88",
        role: ROUTE_KEY.NEW88,
        component: <DrawMoneyPageNew88 />,
      },
      {
        label: "Hi88",
        key: "hi88",
        path: "/withDrawMoney/Hi88",
        role: ROUTE_KEY.HI88,
        component: <DrawMoneyPageHi88 />,
      },
      {
        label: "SHBET",
        key: "shbet",
        path: "/withDrawMoney/ShBet",
        role: ROUTE_KEY.SHBET,
        component: <DrawMoneyPageShBet />,
      },
      {
        label: "789BET",
        key: "789bet",
        path: "/withDrawMoney/789bet",
        role: ROUTE_KEY["789BET"],
        component: <WithdrawalRequest789 />,
      },
      {
        label: "78WIN",
        key: "78Win",
        path: "/withDrawMoney/78Win",
        role: ROUTE_KEY["78WIN"],
        component: <WithdrawalRequest78win />,
      },
      {
        label: "F8BET",
        key: "f8bet",
        path: "/withDrawMoney/f8bet",
        role: ROUTE_KEY.F8BET,
        component: <WithdrawalRequestF8bet />,
      },
      {
        label: "F168",
        key: "f168",
        path: "/withDrawMoney/f168",
        role: ROUTE_KEY.F168,
        component: <WithdrawalRequestF168 />,
      },
      {
        label: "MB66",
        key: "mb66",
        path: "/withDrawMoney/mb66",
        role: ROUTE_KEY.MB66,
        component: <WithdrawalRequestmb66 />,
      },

      {
        label: "OK9",
        key: "ok9",
        path: "/withDrawMoney/ok9",
        role: ROUTE_KEY.OK9,
        component: <WithdrawalRequestok9 />,
      },
    ],
  },
  {
    label: "Lịch sử Giao Dịch ", //Parant Menu
    key: "history",
    children: [
      {
        label: "Jun88",
        key: "jun88History",
        path: "/history/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <HistoryPageJun88 />,
      },
      {
        label: "NEW88",
        key: "new88History",
        path: "/history/New88",
        role: ROUTE_KEY.NEW88,
        component: <HistoryPageNew88 />,
      },
      {
        label: "Hi88",
        key: "Hi88History",
        path: "/history/Hi88",
        role: ROUTE_KEY.HI88,
        component: <HistoryPageHi88 />,
      },
      {
        label: "SHBET",
        key: "ShBetHistory",
        path: "/history/ShBet",
        role: ROUTE_KEY.SHBET,
        component: <HistoryPageShBet />,
      },
      {
        label: "789BET",
        key: "Bet789History",
        path: "/history/Bet789",
        role: ROUTE_KEY["789BET"],
        component: <Bet789HistoryPage />,
      },
      {
        label: "78WIN",
        key: "78WinHistory",
        path: "/history/78WIN",
        role: ROUTE_KEY["78WIN"],
        component: <WIN78HistoryPage />,
      },

      {
        label: "F8BET",
        key: "F8betHistory",
        path: "/history/F8bet",
        role: ROUTE_KEY.F8BET,
        component: <HistoryPageF8bet />,
      },

      {
        label: "F168",
        key: "F168History",
        path: "/history/F168",
        role: ROUTE_KEY.F168,
        component: <HistoryPageF168 />,
      },
      {
        label: "MB66",
        key: "Mb66History",
        path: "/history/Mb66",
        role: ROUTE_KEY.MB66,
        component: <HistoryPageMb66 />,
      },
      {
        label: "QQ88",
        key: "QQ886History",
        path: "/history/QQ88",
        role: ROUTE_KEY.QQ88,
        component: <HistoryPageQQ88 />,
      },
      {
        label: "OK9",
        key: "OK9History",
        path: "/history/OK9",
        role: ROUTE_KEY.OK9,
        component: <HistoryPageOk9 />,
      },
    ],
  },
  {
    label: "Lịch sử cá cược ",
    key: "historybetting",
    children: [
      {
        label: "QQ88",
        key: "QQ88HistoryBetting",
        path: "/historyBetting/QQ88",
        role: ROUTE_KEY.QQ88,
        component: <HistoryBettingPage />,
      },
      {
        label: "F168",
        key: "F168HistoryBetting",
        path: "/historyBetting/F168",
        role: ROUTE_KEY.F168,
        component: <HistoryBettingPageF168 />,
      },
    ],
  },
  {
    label: "Màn hình trang chủ ", //Parant Menu
    key: "homePage",

    children: [
      {
        label: "Jun88",
        key: "homepagejun88",
        path: "/homeScreen/Jun88",
        component: <HomePageJun88 />,
        role: ROUTE_KEY.JUN88,
      },
      {
        label: "789BET",
        key: "homepage789bet",
        path: "/homeScreen/789Bet",
        role: ROUTE_KEY["789BET"],
        component: <HomePage789Bet />,
      },
      {
        label: "78WIN",
        key: "homepage78Win",
        path: "/homeScreen/78Win",
        role: ROUTE_KEY["78WIN"],
        component: <HomePage78Win />,
      },
      {
        label: "NEW88",
        key: "homeNew88",
        path: "/homeScreen/New88",
        role: ROUTE_KEY.NEW88,
        component: <HomeScreenNew88 />,
      },
      {
        label: "Hi88",
        key: "homeHi88",
        path: "/homeScreen/Hi88",
        role: ROUTE_KEY.HI88,
        component: <HomeScreenHi88 />,
      },

      {
        label: "SHBET",
        key: "shbeth",
        path: "/homeScreen/ShBet",
        role: ROUTE_KEY.SHBET,
        component: <HomeScreenShBet />,
      },
      {
        label: "F8BET",
        key: "homeF8bet",
        path: "/homeScreen/F8bet",
        role: ROUTE_KEY.F8BET,
        component: <HomeScreenF8bet />,
      },
      {
        label: "F168",
        key: "homeF168",
        path: "/homeScreen/F168",
        role: ROUTE_KEY.F168,
        component: <HomeScreenF168 />,
      },
      {
        label: "MB66",
        key: "homemb66",
        path: "/homeScreen/mb66",
        role: ROUTE_KEY.MB66,
        component: <HomeScreenMb66 />,
      },
      {
        label: "QQ88",
        key: "homeQQ88",
        path: "/homeScreen/QQ88",
        role: ROUTE_KEY.QQ88,
        component: <HomeScreenQq88 />,
      },
      {
        label: "OK9",
        key: "homeOK9",
        path: "/homeScreen/ok9",
        role: ROUTE_KEY.OK9,
        component: <HomeScreenOk9 />,
      },
    ],
  },
  {
    label: "Màn hình game ", //Parant Menu
    key: "gamePage",
    children: [
      {
        label: "Jun88",
        key: "gameJun88",
        path: "/gameScreen/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <GamePageJun88 />,
      },
      {
        label: "789BET",
        key: "game789Bet",
        path: "/gameScreen/789Bet",
        role: ROUTE_KEY["789BET"],
        component: <GamePage789Bet />,
      },
      {
        label: "78WIN",
        key: "game78Win",
        path: "/gameScreen/78Win",
        role: ROUTE_KEY["78WIN"],
        component: <GamePage78Win />,
      },
      {
        label: "NEW88",
        key: "gameNew88",
        path: "/gameScreen/New88",
        role: ROUTE_KEY.NEW88,
        component: <GamePageNew88 />,
        //  icon: <PayCircleOutlined />,
      },
      {
        label: "Hi88",
        key: "gameHi88",
        path: "/gameScreen/Hi88",
        role: ROUTE_KEY.HI88,
        component: <GamePageHi88 />,
        //  icon: <PayCircleOutlined />,
      },

      {
        label: "SHBET",
        key: "gameshbet",
        path: "/gameScreen/Shbet",
        role: ROUTE_KEY.SHBET,
        component: <GamePageShbet />,
        //  icon: <PayCircleOutlined />,
      },
      {
        label: "F8BET",
        key: "gameF8bet",
        role: ROUTE_KEY.F8BET,
        path: "/gameScreen/F8bet",
        component: <GamePageF8bet />,
      },
      {
        label: "F168",
        key: "gameF168",
        role: ROUTE_KEY.F168,
        path: "/gameScreen/F168",
        component: <GamePageF168 />,
      },
      {
        label: "MB66",
        key: "gameMb66",
        role: ROUTE_KEY.MB66,
        path: "/gameScreen/mb66",
        component: <GamePageMb66 />,
      },
      {
        label: "QQ88",
        key: "gameQQ88",
        role: ROUTE_KEY.QQ88,
        path: "/gameScreen/qq88",
        component: <GamePageQQ88 />,
      },
      {
        label: "OK9",
        key: "gameOk9",
        role: ROUTE_KEY.OK9,
        path: "/gameScreen/ok9",
        component: <GamePageOk9 />,
      },
    ],
  },
  {
    label: "Lịch sử rút tiền",
    key: "historyWidthDraw",
    children: [
      {
        label: "Jun88",
        key: "historyWidthDrawJun88",
        path: "/withdrawal-history/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <WidthdrawalHistoryJun88 />,
      },
      {
        label: "MB66",
        key: "historyWidthDrawMb66",
        path: "/withdrawal-history/Mb66",
        role: ROUTE_KEY.MB66,
        component: <WidthdrawalHistoryMb66 />,
      },
    ],
  },
  {
    label: "Lịch sử hoàn trả",
    key: "historyRefund",
    children: [
      {
        label: "Jun88",
        key: "historyRefundJun88",
        path: "/history-refund/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <RefuntHistory />,
      },
    ],
  },
  {
    label: "Thông báo nạp tiền",
    key: "notifiWidrawn",
    children: [
      {
        label: "Jun88",
        key: "notifiWidrawnJun88",
        path: "/withdrawal-notice/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <NoticeJun88 />,
      },
      {
        label: "Mb66",
        key: "notifiWidrawnMb66",
        path: "/withdrawal-notice/Mb66",
        role: ROUTE_KEY.MB66,
        component: <NoticeMb66 />,
      },
    ],
  },
  {
    label: "Khuyến mãi",
    key: "promotion",
    children: [
      {
        label: "Hi88",
        key: "promotionHi88",
        path: "/promotion/Hi88",
        role: ROUTE_KEY.HI88,
        component: <PromotionHi88 />,
      },
      {
        label: "QQ88",
        key: "promotionQQ88",
        path: "/promotion/QQ88",
        role: ROUTE_KEY.QQ88,
        component: <PromotionQQ88 />,
      },
    ],
  },
  {
    label: "Phần thưởng",
    key: "award",
    children: [
      {
        label: "QQ88",
        key: "awardQQ88",
        path: "/award/QQ88",
        role: ROUTE_KEY.QQ88,
        component: <PrizeQQ88 />,
      },
    ],
  },
  {
    label: "Cấp VIP",
    key: "vipLevel",
    children: [
      {
        label: "Jun88",
        key: "vipLevelJun88",
        path: "/vip-level/Jun88",
        role: ROUTE_KEY.JUN88,
        component: <LevelVipJun88 />,
      },
      {
        label: "NEW88",
        key: "vipLevelNew88",
        path: "/vip-level/New88",
        role: ROUTE_KEY.NEW88,
        component: <LevelVipNew88 />,
      },
      {
        label: "789BET",
        key: "vipLevel789BET",
        path: "/vip-level/789BET",
        role: ROUTE_KEY["789BET"],
        component: <LevelVip789BET />,
      },
      {
        label: "78WIN",
        key: "vipLevel78WIN",
        path: "/vip-level/78Win",
        role: ROUTE_KEY["78WIN"],
        component: <LevelVip78WIN />,
      },
      {
        label: "F168",
        key: "vipLevelF168",
        path: "/vip-level/F168",
        role: ROUTE_KEY.F168,
        component: <LevelVipF168 />,
      },
      {
        label: "Hi88",
        key: "vipLevelHi88",
        path: "/vip-level/hi88",
        role: ROUTE_KEY.HI88,
        component: <LevelVipHi88 />,
      },
      {
        label: "Mb66",
        key: "vipLevelMb66",
        path: "/vip-level/mb66",
        role: ROUTE_KEY.MB66,
        component: <LevelVipMb66 />,
      },
      {
        label: "SHBET",
        key: "vipLevelSHBET",
        path: "/vip-level/shbet",
        role: ROUTE_KEY.SHBET,
        component: <LevelVipSHBET />,
      },
    ],
  },
  {
    label: "Tạo hóa đơn",
    key: "createInvoice",
    children: [
      {
        label: "Tạo hóa đơn",
        key: "createInvoice",
        path: "/create-invoice",
        component: <CreateInvoicePage />,
      },
    ],
  },
  {
    label: "App Fake ",
    key: "createClient",
    children: [
      {
        label: "Tạo thông tin khách",
        key: "webapp",
        path: "/create-information-client",
        component: <CreateInformation />,
      },
      {
        label: "Tải App",
        key: "fileapp",
        path: "/download-app",
        component: <DownloadApp />,
      },
    ],
  },
];

const routeAuthWithoutMenu = [
  //billTransaction
  {
    key: "billTransaction",
    path: "/billTransaction/vcb",
    component: <BillTransactionVCBPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/tcb",
    component: <BillTransactionTCBPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/acb",
    component: <BillTransactionAcbPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/mbb",
    component: <BillTransactionMBBPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/tpbank",
    component: <BillTransactionTPBPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/momo",
    component: <BillTransactionMomoPage />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/agribank",
    component: <BillTransacAgribank />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/vietinbank",
    component: <BillTransacVietinbank />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/BIDV",
    component: <BillTransacBIDV />,
  },
  {
    key: "billTransaction",
    path: "/billTransaction/ocb",
    component: <BillTransacOCB />,
  },
  //billTransfer
  {
    key: "billTransfer",
    path: "/billTransfer/vcb",
    component: <BillTransferVCBPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/tech",
    component: <BillTransferTECHPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/bidv",
    component: <BillTransferBIDVPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/sacombank",
    component: <BillTransferSacombankPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/vietinbank",
    component: <BillTransferVietinbankPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/vpbank",
    component: <BilltransferVPbankPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/mbbank",
    component: <BillTransferMBbankPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/momo",
    component: <BillTransferMomoPagev2 />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/msb",
    component: <BillTransferMSBPage />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/agribank",
    component: <BillTransferAgribank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/tpbank",
    component: <BillTransferTpBank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/acb",
    component: <BillTransferACB />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/ocb",
    component: <BillTransferOCB />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/dongA",
    component: <BillTransferDongA />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/pvcombank",
    component: <BillTransferPvcombank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/namabank",
    component: <BillTransferNamAbank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/cakebank",
    component: <BillTransferCakebank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/seabank",
    component: <BillTransferSeabank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/vietteMoneyl",
    component: <BillTransferViettelMoney />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/lpbank",
    component: <BillTransferLpbank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/bvbank",
    component: <BillTransferBvbank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/VIBbank",
    component: <BillTransferInvoice />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/SHBbank",
    component: <BillTransferSHBBank />,
  },
  {
    key: "billTransfer",
    path: "/billTransfer/HDbank",
    component: <BillTransferHDBank />,
  },
  //billBalance
  {
    key: "billBalance",
    path: "/billBalance/acb",
    component: <BillBalanceACBPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/bidv",
    component: <BillBalanceBIDVPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/agribank",
    component: <BillBalanceAgribankPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/vcb",
    component: <BillBalanceVietComBankPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/tpbank",
    component: <BillBalanceTpBank />,
  },
  {
    key: "billBalance",
    path: "/billBalance/vpbank",
    component: <BillBalanceVpBankPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/vietinbank",
    component: <BillBalanceViettinPage />,
  },
  {
    key: "billBalance",
    path: "/billBalance/mbbank",
    component: <BillBalanceMbbanhk />,
  },
  {
    key: "billBalance",
    path: "/billBalance/techcombank",
    component: <BillBalanceTechComBank />,
  },
  //smsbanking
  {
    key: "smsbanking",
    path: "/smsbanking/vcb",
    component: <SmsBankVietComBankPage />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/DongA",
    component: <SmsBankDongABank />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/bidv",
    component: <SmsBankBidv />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/agribank",
    component: <SmsBankAgribankBankPage />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/vietinbank",
    component: <SmsBankVietinBankPage />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/acb",
    component: <SmsBankACB />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/vpbank",
    component: <SmsBankTpbank />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/mbbank",
    component: <SmsBankMBBankPage />,
  },
  {
    key: "smsbanking",
    path: "/smsbanking/ocb",
    component: <SmsBankOCBBankPage />,
  },
  //webapp

  {
    key: "webapp",
    path: "/create-information-client/detail",
    component: <CreateClient />,
  },
  {
    key: "fileapp",
    path: "/download-app/detail",
    component: <FileApp />,
  },
];

const itemsMenuSideBar = authProtectedRoutes.map((item, index) => {
  if (item.path === "/") return null;
  return getItem(
    item.label,
    item.key,
    item.icon,
    item.children?.map((itemChildren) => {
      return getItem(itemChildren.label, itemChildren.key, itemChildren.icon);
    })
  );
});

// submenu keys of first level
const rootSubmenuKeys = authProtectedRoutes.map((item, index) => {
  return item.key;
});

const AuthProtectedLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [components, setComponents] = useState(null);
  const [openKeys, setOpenKeys] = useState(["dashboard"]);
  const [selectedKeys, setSelectedKeys] = useState("dashboard");
  const [role, setRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [roleName, setRoleName] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    //set navigation defaults for url "/"
    if (location.pathname === "/") return navigate("/dashboard");
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.listRole) {
      // const userRole = [];
      // userRole.push(userData.roleName);
      setRoleName(userData.listRole.toString().toUpperCase());
    }

    let _item = "";
    authProtectedRoutes.forEach((element) => {
      element.children &&
        element.children.forEach((elementChildren) => {
          if (elementChildren.path === location.pathname) {
            _item = elementChildren;
            return;
          }
        });
    });

    routeAuthWithoutMenu.forEach((element) => {
      if (element.path === location.pathname) {
        _item = element;
        return;
      }
    });

    if (!_item) return navigate("/404");

    setSelectedKeys(_item.key);
    handleSetOpenKeys(_item.key);
    setRole(_item.role || "user");
    setComponents(_item.component);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  useEffect(() => {
    let filteredMenuItems = [];
    // console.log(roleName);
    const keysToCheckBill = [
      "withDrawMoney",
      "gamePage",
      "homePage",
      "history",
      "historyWidthDraw",
      "vipLevel",
      "historyRefund",
      "historybetting",
      "createInvoice",
      "createClient",
      "notifiWidrawn",
      "promotion",
      "award",
    ];
    const keysToCheck = [
      "withDrawMoney",
      "historybetting",
      "gamePage",
      "homePage",
      "history",
      "historyWidthDraw",
      "vipLevel",
      "historyRefund",
      "notifiWidrawn",
      "award",
      "promotion",
    ];
    const keysToHI = ["promotion"];
    const keysToQQ88 = ["award"];
    const keysToJun = ["historyWidthDraw", "historyRefund", "notifiWidrawn"];
    const keysToMb66 = ["historyWidthDraw", "notifiWidrawn"];
    if (roleName.includes("ADMIN")) {
      filteredMenuItems = itemsMenuSideBar;
    } else if (roleName.includes("BILL")) {
      filteredMenuItems = itemsMenuSideBar.map((item) => {
        if (!keysToCheckBill.includes(item.key)) {
          return item;
        }
        return null;
      });
    } else {
      filteredMenuItems = itemsMenuSideBar.map((item) => {
        if (keysToCheck.includes(item.key)) {
          if (
            item.key === "historyWidthDraw" ||
            item.key === "promotion" ||
            item.key === "historyRefund" ||
            item.key === "notifiWidrawn" ||
            item.key === "award"
          ) {
            if (roleName.includes("JUN88") && keysToJun.includes(item.key)) {
              const filteredChildren = item.children.filter((child) =>
                roleName.includes(child.label.toString().toUpperCase())
              );
              return {
                ...item,
                children: filteredChildren,
              };
            } else if (roleName.includes("HI88") && keysToHI.includes(item.key)) {
              const filteredChildren = item.children.filter((child) =>
                roleName.includes(child.label.toString().toUpperCase())
              );
              return {
                ...item,
                children: filteredChildren,
              };
            } else if (roleName.includes("MB66") && keysToMb66.includes(item.key)) {
              const filteredChildren = item.children.filter((child) =>
                roleName.includes(child.label.toString().toUpperCase())
              );
              return {
                ...item,
                children: filteredChildren,
              };
            } else if (roleName.includes("QQ88") && keysToQQ88.includes(item.key)) {
              const filteredChildren = item.children.filter((child) =>
                roleName.includes(child.label.toString().toUpperCase())
              );
              return {
                ...item,
                children: filteredChildren,
              };
            } else {
              return null;
            }
          } else {
            const filteredChildren = item.children.filter((child) =>
              roleName.includes(child.label.toString().toUpperCase())
            );
            return {
              ...item,
              children: filteredChildren,
            };
          }
        }
        return item;
      });
    }
    setMenuItems(filteredMenuItems);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsMenuSideBar, roleName]);

  //  useEffect(() => {
  //    let filteredMenuItems = [];
  //    // console.log(roleName);
  //    const keysToCheckBill = [
  //      "withDrawMoney",
  //      "gamePage",
  //      "homePage",
  //      "history",
  //      "historyWidthDraw",
  //      "vipLevel",
  //      "historyRefund",
  //      "createInvoice",
  //      "createClient",
  //      "notifiWidrawn",
  //    ];
  //    const keysToCheck = [
  //      "withDrawMoney",
  //      "gamePage",
  //      "homePage",
  //      "history",
  //      "historyWidthDraw",
  //      "vipLevel",
  //      "historyRefund",
  //      "notifiWidrawn",
  //    ];
  //    if (roleName.includes("ADMIN")) {
  //      filteredMenuItems = itemsMenuSideBar;
  //    } else if (roleName.includes("BILL")) {
  //      filteredMenuItems = itemsMenuSideBar.map((item) => {
  //        if (!keysToCheckBill.includes(item.key)) {
  //          return item;
  //        }
  //        return null;
  //      });
  //    } else {
  //      filteredMenuItems = itemsMenuSideBar.map((item) => {
  //        if (keysToCheck.includes(item.key)) {
  //          if (item.key === "historyWidthDraw" || item.key === "historyRefund" || item.key === "notifiWidrawn") {
  //            if (roleName.includes("JUN88")) {
  //              return item;
  //            } else {
  //              return null;
  //            }
  //          } else {
  //            const filteredChildren = item.children.filter((child) =>
  //              roleName.includes(child.label.toString().toUpperCase())
  //            );
  //            return {
  //              ...item,
  //              children: filteredChildren,
  //            };
  //          }
  //        }
  //        return item;
  //      });
  //    }
  //    setMenuItems(filteredMenuItems);
  //    //  eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, [itemsMenuSideBar, roleName]);
  const onClickItemMenu = (item) => {
    authProtectedRoutes.forEach((element) => {
      element.children &&
        element.children.forEach((elementChildren) => {
          if (elementChildren.key === item.key) {
            return navigate(elementChildren.path);
          }
        });
    });
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleSetOpenKeys = (expression) => {
    switch (expression) {
      case "billTransfer":
      case "billTransaction":
      case "billBalance":
      case "smsbanking":
      case "upload":
        setOpenKeys(["bill"]);
        break;
      case "jun88":
      case "new88":
      case "hi88":
      case "789bet":
      case "78Win":
      case "f8bet":
      case "f168":
      case "shbet":
      case "mb66":
      case "ok9":
        setOpenKeys(["withDrawMoney"]);
        break;
      case "jun88History":
      case "new88History":
      case "Bet789History":
      case "78WinHistory":
      case "Hi88History":
      case "ShBetHistory":
      case "F8betHistory":
      case "F168History":
      case "Mb66History":
      case "QQ886History":
      case "OK9History":
        setOpenKeys(["history"]);
        break;
      case "QQ88HistoryBetting":
      case "F168HistoryBetting":
        setOpenKeys(["historybetting"]);
        break;
      case "homepagejun88":
      case "homeNew88":
      case "homepage789bet":
      case "homepage78Win":
      case "homeHi88":
      case "homeF8bet":
      case "homeF168":
      case "homemb66":
      case "homeQQ88":
      case "shbeth":
      case "homeOK9":
        setOpenKeys(["homePage"]);
        break;
      case "gameJun88":
      case "gameNew88":
      case "game789Bet":
      case "game78Win":
      case "gameHi88":
      case "gameF8bet":
      case "gameF168":
      case "gameMb66":
      case "gameQQ88":
      case "gameshbet":
      case "gameOk9":
        setOpenKeys(["gamePage"]);
        break;
      case "historyWidthDrawJun88":
      case "historyWidthDrawMb66":
        setOpenKeys(["historyWidthDraw"]);
        break;
      case "historyRefundJun88":
        setOpenKeys(["historyRefund"]);
        break;
      case "notifiWidrawnJun88":
      case "notifiWidrawnMb66":
        setOpenKeys(["notifiWidrawn"]);
        break;
      case "promotionHi88":
      case "promotionQQ88":
        setOpenKeys(["promotion"]);
        break;
      case "awardQQ88":
        setOpenKeys(["award"]);
        break;
      case "vipLevelNew88":
      case "vipLevel789BET":
      case "vipLevel78WIN":
      case "vipLevelF168":
      case "vipLevelJun88":
      case "vipLevelHi88":
      case "vipLevelSHBET":
      case "vipLevelMb66":
        setOpenKeys(["vipLevel"]);
        break;
      case "createInvoice":
        setOpenKeys(["createInvoice"]);
        break;
      case "webapp":
      case "fileapp":
        setOpenKeys(["createClient"]);
        break;
      default:
        setOpenKeys(["dashboard"]);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header />
      <Layout>
        <Sider
          width={250}
          className="site-layout-background"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedKeys]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={menuItems}
            onClick={(item) => onClickItemMenu(item)}
          />
        </Sider>
        <Content
          className="site-layout-background"
          style={{
            padding: 12,
            margin: 0,
          }}
        >
          <RoleProtected role={role}>{components}</RoleProtected>
        </Content>
      </Layout>
    </Layout>
  );
};
export default AuthProtectedLayout;
